export const ROUTES = {
    PRODUCTS: {
        MAIL: '/mail',
        DRIVE: '/drive',
        CAMERA: '/camera',
        MESSAGE: '/message',
        PORTABLE: '/portable',
    },
    // SOLUTIONS: {
    //     GATEWAY: '/zunu-gateway',
    //     GATEWAY_GUIDE: '/zunu-gateway/developer-guide',
    //     DB: '/zunu-db',
    //     DB_GUIDE: '/zunu-db/developer-guide',
    //     SECURE_NUMERIC: '/secure-numeric',
    //     SECURE_STRING: '/secure-string',
    //     SECURE_DATASTRUCTURES: '/secure-data-structures',
    //     FHE_CONSOLE: '/fhe-console',
    //     ZUNU_BUSINESS: `/zunu-business`,
    //     ZUNU_CONTACT_US: '/zunu-business/contact-us',
    //     PATENTED_FHE_TECHNOLOGY: '/zunu-business/patented-fhe',
    //     ZUNU_PLATFORM_SDK: '/platform-sdks',

    //     USECASES: {
    //         FINANCE: `/zunu-business/usecases/finance`,
    //         HEALTHCARE: `/zunu-business/usecases/healthcare`,
    //         GOVERNMENT: `/zunu-business/usecases/government`,
    //         COMMERCE: `/zunu-business/usecases/commerce`,
    //     },

    //     DATASTRUCTURES_API_DOCS: {
    //         BINARY_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureBinaryTree.html',
    //         BINARY_SEARCH_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureBinarySearchTree.html',
    //         AVL_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureAVLTree.html',
    //         DOUBLY_LINKED_LIST: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/list/SecureDoublyLinkedList.html',
    //         CIRCULAR_LINKED_LIST: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/list/SecureCircularLinkedList.html',
    //         TRIE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/trie/SecureTrie.html',
    //         B_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureBTree.html',
    //         B_PLUS_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureBPlusTree.html',
    //         GRAPH: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/graph/SecureGraph.html',
    //         HASH_TABLE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/hashtable/SecureHashTable.html',
    //         ARRAY_LIST: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/list/SecureArrayList.html',
    //         LINKED_LIST: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/list/SecureLinkedList.html',
    //         VECTOR: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/list/SecureVector.html',
    //         STACK: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/stack/SecureStack.html',
    //         ARRAY_DEQUE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/arraydeque/SecureArrayDeque.html',
    //         HASH_SET: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/set/SecureHashSet.html',
    //         TREE_SET: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/set/SecureTreeSet.html',
    //         HASH_MAP: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/map/SecureHashMap.html',
    //         TREE_MAP: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/map/SecureTreeMap.html',
    //         MAX_HEAP: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/heap/SecureMaxHeap.html',
    //         MIN_HEAP: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/heap/SecureMinHeap.html',
    //         SEGMENT_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureSegmentTree.html',
    //         RADIX_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureRadixTree.html',
    //         OCTREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureOctree.html',
    //         QUAD_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureQuadTree.html',
    //         R_TREE: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/tree/SecureRTree.html',
    //         CONCURRENT_HASH_MAP: 'https://javadocs.gozunu.com/com/ziroh/secure/collections/map/SecureConcurrentHashMap.html',
    //     },  

    //     // DATASTRUCTURES_QUICK_START: {
    //         BINARY_TREE: '/secure-data-structures/binary-tree',
    //         BINARY_SEARCH_TREE: '/secure-data-structures/binary-search-tree',
    //         AVL_TREE: '/secure-data-structures/avl-tree',
    //         DOUBLY_LINKED_LIST: '/secure-data-structures/doubly-linked-list',
    //         CIRCULAR_LINKED_LIST: '/secure-data-structures/circular-linked-list',
    //         TRIE: '/secure-data-structures/trie',
    //         B_TREE: '/secure-data-structures/b-tree',
    //         B_PLUS_TREE: '/secure-data-structures/b-plus-tree',
    //         GRAPH: '/secure-data-structures/graph',
    //         HASH_TABLE: '/secure-data-structures/hash-table',
    //         ARRAY_LIST: '/secure-data-structures/array-list',
    //         LINKED_LIST: '/secure-data-structures/linked-list',
    //         VECTOR: '/secure-data-structures/vector',
    //         STACK: '/secure-data-structures/stack',
    //         ARRAY_DEQUE: '/secure-data-structures/array-deque',
    //         HASH_SET: '/secure-data-structures/hash-set',
    //         TREE_SET: '/secure-data-structures/tree-set',
    //         HASH_MAP: '/secure-data-structures/hash-map',
    //         TREE_MAP: '/secure-data-structures/tree-map',
    //         MAX_HEAP: '/secure-data-structures/max-heap',
    //         MIN_HEAP: '/secure-data-structures/min-heap',
    //         SEGMENT_TREE: '/secure-data-structures/segment-tree',
    //         RADIX_TREE: '/secure-data-structures/radix-tree',
    //         OCTREE: '/secure-data-structures/octree',
    //         QUAD_TREE: '/secure-data-structures/quad-tree',
    //         R_TREE: '/secure-data-structures/r-tree',
    //         CONCURRENT_HASH_MAP: '/secure-data-structures/concurrent-hash-map',
    //     // }, 
    // },
    POLICY_ROUTES:{
        CAMERA: '/privacy-policy?product=camera',
        MAIL: '/privacy-policy?product=mail',
        DRIVE: '/privacy-policy?product=drive',
        MESSGAE: '/privacy-policy?product=message',
    },
    TERMS_ROUTES: {
        CAMERA: '/terms-of-service?product=camera',
        MAIL: '/terms-of-service?product=mail',
        DRIVE: '/terms-of-service?product=drive',
        MESSAGE: '/terms-of-service?product=message',
    },

    HOME: '/',
    POLICY: '/privacy-policy',
    TERMS: '/terms-of-service',
    PRICING: '/pricing',
    SUPPORT: '/support',
    BLOGS: 'https://blogs.zunuprivacy.com/',
    DOWNLOAD: '/downloads',
    CONTACT_US: '/contact-us',
    CAREERS: 'https://careers.gozunu.com',
    ACADEMIC_ALLIANCE: 'https://academic.ziroh.com',
    ACADEMIC_ALLIANCE_PARENTS: 'https://academic.ziroh.com/partner-universities',
    THINGS_ZUNU_DOES: '/things-zunu-does',
    CRYPTO_CHALLENGE: '/crypto-challenge',
    HACKATHON: '/hackathon',
    DELETE_ACCOUNT: '/delete-account',

    SOCIAL_MEDIA:{
        LINKEDIN: 'https://www.linkedin.com/company/zirohlabs/',
        LINKEDIN_BUSINESS: 'http://linkedin.com/showcase/zunuforbusiness',
        FACEBOOK: 'https://www.facebook.com/zirohlabs/',
        X: 'https://x.com/zirohlabs',
        INSTAGRAM: 'https://www.instagram.com/zirohlabs/',
        YOUTUBE: 'https://www.youtube.com/@zirohlabs',
    },

}

