import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styles from './deleteAccount.module.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { createTicketService } from '../../App.registration';
import { toast } from 'react-toastify';
import { DELETE_ACCOUNT_GRP_ID } from '../../_shared/constants/constants';

function DeleteAccount() {
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm();
    const [zunuIdType, setZunuIdType] = useState<'email' | 'phone'>('email');
    const [isLoading, setisLoading] = useState<boolean>(false);

    const onSubmit = async (data: any) => {
        setisLoading(true);

        // console.log("[handleFormSubmit]", { data });

        const formData = new FormData();
        formData.append("name", data.contactEmail ? data.contactEmail : data.zunuEmail);
        formData.append("email", data.contactEmail ? data.contactEmail : data.zunuEmail);
        formData.append("subject", "Zunu Account Deletion Request");
        formData.append("description", data.message ? data.message : data.zunuEmail ? data.zunuEmail : data.zunuPhone);
        formData.append("status", "2");
        formData.append("priority", "1");
        formData.append("group_id", DELETE_ACCOUNT_GRP_ID);
        {
            data.zunuEmail && zunuIdType === 'email' &&
                formData.append("custom_fields[cf_zunu_account]", data.zunuEmail)
        }
        {
            data.zunuPhone && zunuIdType === 'phone' &&
                formData.append("custom_fields[cf_zunu_account]", data.zunuPhone)
        }


        try {
            const response = await createTicketService.CreateTicket(formData);

            if (response?.status === 200) {
                toast.success("Your request has been raised, we will get back to you soon!");
                reset();
            } else {
                toast.error("Failed to raise a request, please try again later");
            }
        }
        catch (error) {
            console.log(error);

        } finally {
            setisLoading(false)
        }
    };

    const renderError = (error: any) => {
        if (error && typeof error.message === 'string') {
            return <p className={styles.error}>{error.message}</p>;
        }
        return null;
    };

    return (
        <div className={styles.deleteAccount}>
            <span className={styles.shadow}></span>

            <div className={styles.formWrapper}>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <p className={styles.title}>Raise a Request to Delete Your Zunu Account</p>


                    <div className={styles.zunuIdWrapper}>
                        <label htmlFor="zunuIdType">Please enter the email address or phone number associated with your Zunu account. *</label>
                        <div className={styles.radiosWrapper}>
                            <div className={styles.wrapper}>
                                <input
                                    type="radio"
                                    value="email"
                                    {...register('zunuIdType', { required: true })}
                                    checked={zunuIdType === 'email'}
                                    onChange={() => setZunuIdType('email')}
                                />
                                <label>Email</label>
                            </div>
                            <div className={styles.wrapper}>
                                <input
                                    type="radio"
                                    value="phone"
                                    {...register('zunuIdType')}
                                    checked={zunuIdType === 'phone'}
                                    onChange={() => setZunuIdType('phone')}
                                />
                                <label>Phone number</label>
                            </div>
                        </div>
                        {zunuIdType === 'email' ? (
                            <input
                                type="email"
                                placeholder="Email Id linked with Zunu"
                                {...register('zunuEmail', { required: 'Email is required' })}
                            />
                        ) : (
                            <Controller
                                name="zunuPhone"
                                control={control}
                                rules={{ required: 'Phone number is required' }}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        placeholder="Enter phone number"
                                        defaultCountry="US"
                                        countryCallingCodeEditable={false}
                                        focusInputOnCountrySelection
                                        international={true}
                                        className={styles.phoneInput}
                                    />
                                )}
                            />
                        )}
                        {zunuIdType === "email" &&
                            renderError(errors.zunuEmail)
                        }
                        {zunuIdType === "phone" &&
                            renderError(errors.zunuPhone)
                        }
                    </div>

                    {zunuIdType === "phone" &&
                        <div className={styles.emailWrapper}>
                            <label htmlFor="contactEmail">Let us know where we can reach you *</label>
                            <input
                                id="contactEmail"
                                type="email"
                                placeholder="Email Id"
                                {...register('contactEmail', { required: 'Email is required' })}
                            />
                            {renderError(errors.contactEmail)}
                        </div>
                    }

                    <div className={styles.reasonWrapper}>
                        <label htmlFor="reason">Please let us know why you're deleting your account *</label>
                        <select
                            id="reason"
                            {...register('reason', { required: 'Reason is required' })}
                            defaultValue=""
                        >
                            <option value="" hidden selected></option>
                            <option value="I have another Zunu Account">I have another Zunu Account</option>
                            <option value="I no longer need privacy or encryption.">I no longer need privacy or encryption.</option>
                            <option value="I am switching to a different solution.">I am switching to a different solution.</option>
                            <option value="I have concerns about data security.">I have concerns about data security.</option>
                            <option value="I found the Zunu difficult to use.">I found the Zunu difficult to use.</option>
                            <option value="The features don't meet my needs.">The features don't meet my needs.</option>
                            <option value="There are technical issues.">There are technical issues.</option>
                            <option value="My reason is not listed.">My reason is not listed.</option>
                        </select>
                        {renderError(errors.reason)}
                    </div>

                    <div className={styles.commentWrapper}>
                        <label htmlFor="message">How can we improve Zunu? <i>(optional)</i></label>
                        <textarea
                            id="message"
                            {...register('message')}
                            cols={30}
                            rows={5}
                            placeholder="suggestions..."
                        ></textarea>
                    </div>

                    <div className={styles.ctaWrapper}>
                        <p className={styles.title}>What happens when you delete your account?</p>
                        <p className={styles.subTitle}>This action will permanently delete your account and all associated data. Account deletion cannot be reversed.</p>
                        <button type="submit" disabled={isLoading}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DeleteAccount;
