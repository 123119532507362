import { FileUploadStatus } from "../types/fileUploadStatusType";

export enum PLATFORM {
  WIN = "windows",
  MAC = "mac",
  MACIntel = "mac_intel",
  AND = "android",
  INDUS = "indus",
  IOS = "iOS",
  LINUX = "linux",
  LINUX18 = "linux_18",
  LINUX22 = "linux_22",
  WIN_PORATBLE = "windows_portable",
}

export enum THEME {
  DARK = "dark",
  LIGHT = "light",
}

export enum DEVICE {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}

export const PRICING = {
  INDIA: {
    FREE: `0`,
    PRO: `49`,
    FAMILY: `249`,
  },
  USA: {
    FREE: `0`,
    PRO: `9.99`,
    FAMILY: `16.99`,
  },
  EUROPE: {
    FREE: `0`,
    PRO: `8.99`,
    FAMILY: `12.99`,
  },
  JAPAN: {
    FREE: `0`,
    PRO: `1430`,
    FAMILY: `1999`,
  },
}

export const ZUNU_SUPPORT_GRP_ID= "150000376973";
export const DELETE_ACCOUNT_GRP_ID= "150000415795";

export const CHUNK_SIZE = 4 * 1048756;

export const FILE_EXTENTIONS = {
  AUDIO: [
    "mpeg",
    "wav",
    "ogg",
    "mp4",
    "x-ms-wma",
    "aac",
    "flac",
    "x-aiff",
    "x-midi",
    "x-wavpack",
  ],
  VIDEO: [
    "3gpp",
    "mp4",
    "quicktime",
    "x-msvideo",
    "x-flv",
    "x-matroska",
    "x-ms-wmv",
    "webm",
    "vnd.dlna.mpeg-tts",
    "x-m4v",
  ],
  DOCUMENTS: [
    "doc",
    "docx",
    "txt",
    "rtf",
    "odt",
    "odp",
    "pages",
    "tex",
    "html",
    "htm",
    "xml",
    "json",
  ],
  SPREAD_SHEET: ["csv", "xls", "xlsx", "ods"],
  FALLBACK: ["svg", "tif"],
};

export const FILE_STATUS = {
  SUCCESS: "FILE_UPLOAD_SUCCESS",
  FAIL: "FILE_UPLOAD_FAILED",
  ENCRYPT_SUCCESS: "FILE_ENCRYPTED",
};

export const BLOG_URLS = {
  SWISS_PRIVACY: "https://blogs.gozunu.com/the-swiss-privacy/",
};

export const FHE_PRODUCTS ={
  secureStringClient: 'SecureString Client',
  secureStringOperations: 'SecureString Operations',
  secureNumericClient: 'SecureNumeric Client',
  secureNumericOperations: 'SecureNumeric Operations',
}