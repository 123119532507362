import * as React from "react";
import styles from "./home.module.scss";
import floaterStyles from "../_shared/components/FloaterDownload/floaterDownload.module.scss";
import { Helmet } from "react-helmet";
import { ReactComponent as ZunuCameraIcon } from "../../assets/svg/zunu_cam_icon.svg";
import { ReactComponent as ZunuMailIcon } from "../../assets/svg/zunu_mail_icon.svg";
import { ReactComponent as ZunuDriveIcon } from "../../assets/svg/zunu_drive_icon.svg";
import { ReactComponent as ZunuMessageIcon } from "../../assets/svg/zunu_msg_icon.svg";
import { ReactComponent as ScrollArrow } from "../../assets/svg/arrow_double.svg";
import Button from "../../_shared/components/Button/Button.component";

import HomePricingCardsEnum from "./components/HomePricingCardsEnum/HomePricingCardsEnum";
import HomeDownloadCaptionEnum from "./components/HomeDownloadCaptionEnum/HomeDownloadCaptionEnum";
import { useHomehook } from "./home.hook";
import { DEVICE, PLATFORM, THEME } from "../../_shared/constants/constants";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

import { useOsStore } from "../../StateManager/OsStore";
import { useThemeStore } from "../../StateManager/ThemeStore";
// import {Testimonials} from "./components/Testimonials/testimonials";
import { TESTIMONIALS } from "./components/Testimonials/testimonials_data";
import { SwissPrivacy } from "./components/SwissPrivacy/swissPrivacy.component";
import ChooseServer from "./components/chooseServer/chooseServer.component";
import { isFunctionOrConstructorTypeNode } from "typescript";
import { useDownloadBtnStore } from "../../StateManager/DownloadBtnStore";
import useNavbarHook from "../../_shared/components/Navbar/navbar.hook";
import { useDownloadHook } from "../Downloads/download.hook";
import { useDeviceStore } from "../../StateManager/DeviceStore";
import { WhitePapers } from "../_shared/components/WhitePapers/whitePapers.component";
import { WHITEPAPERS } from "../_shared/components/WhitePapers/whitePapersData";
import { VIDEOS } from "../../_shared/constants/videos_data";
import { ProductFaqs } from "../_shared/components/productFaqs/productFaqs.component";
import  {QUESTIONS} from '../Support/questions_data'


//LAZY LOADING
import { lazily } from 'react-lazily';
import { ROUTES } from "../../_shared/constants/routes";
const { Captions } = lazily(() => import('./components/Captions/captions'));
const { ZunuDescription } = lazily(() => import('./components/ZunuDescription/zunuDescription.component'));
const { ProductCards } = lazily(() => import('./components/ProductCards/productCards.component'));
const { HomeDeviceToDeviceEncryption } = lazily(() => import('./components/HomeD2DE2E/HomeDeviceToDeviceEncryption.component'));
const { ZunuThings } = lazily(() => import('./components/ZunuThings/zunuThings'));
const { FloaterDownload } = lazily(() => import('../_shared/components/FloaterDownload/floaterDownload.component'));
const { DecentralizedPrivacy } = lazily(() => import('./components/DecentralizedPrivacy/decentralizedPrivacy.component'));
const { ProductVideos } = lazily(() => import('../_shared/components/productVideos/productVideos'));
const { Testimonials } = lazily(() => import('./components/Testimonials/testimonials'));


const Home = () => {
  const headerRef: any = useRef();
  const { os, setOs } = useOsStore();
  const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

  const { theme, localization, ref } = useHomehook(
    // isButtonVisible,
    // setIsButtonVisible,
    headerRef,
  );

  const { device, setDevice } = useDeviceStore();
  const { handleDownloadClick } = useNavbarHook();
  const {
    handleBuildDownload,
  } = useDownloadHook();

  useEffect(() => {
    setIsDownloadBtn(true)
  }, [])


  function scrollPage() {
    window.scrollBy(0, window.innerHeight)
  }


  return (
    <section className={styles.homeContainer}>
      <Helmet>
        <meta
          name="description"
          content="Discover Zunu, the ultimate data privacy solution. Protect your sensitive information."
        />
        <title>Zunu - Secure Encryption for Emails, Files, Messages, Photos & Videos</title>
        <meta name="keywords" content="zunu privacy encryption secure files secure mails data breach"></meta>
        <meta property="og:site_name" content="Zunu" />
        <meta
          property="og:title"
          content="Zunu - Secure Encryption for Emails, Files, Messages, Photos & Videos"
        />
        <meta property="og:url" content="https://zunuprivacy.com/" />
        <meta
          property="og:description"
          content="Discover Zunu, the ultimate data privacy solution. Protect your sensitive information."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://zunuprivacy.com/icon192.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zirohlabs" />
        <meta
          name="twitter:title"
          content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms."
        />
        <meta
          name="twitter:description"
          content="Discover Zunu, the ultimate data privacy solution. Protect your sensitive information."
        />
        <meta
          name="twitter:image"
          content="https://zunuprivacy.com/icon192.png"
        />
      </Helmet>

      <div className={styles.headerContainer}>
        {/* {theme == THEME.LIGHT && localization == "0" ? (
          <Mandala_Light className={styles.mandalaDesign} />
        ) : (
          ""
        )}

        {theme == THEME.DARK && localization == "0" ? (
          <Mandala_Dark className={styles.mandalaDesign} />
        ) : (
          ""
        )} */}

        <div className={styles.bgCircle}></div>

        <div ref={headerRef} className={styles.header}>
          <div className={styles.heading}>
            Keep your private life <span>private.</span>
          </div>

          {device == DEVICE.MOBILE &&
            <p className={styles.description}>
              Experience 100% privacy for your photos, videos, messages, emails, and files on Zunu. For Android, iOS, Windows, Linux platforms and even USB storage devices.
            </p>
          }
          <div className={styles.zunuProductsContainer}>
            <p className={styles.productsContainerHeading}>
              Zunu Privacy Suite
            </p>
            <div className={styles.productsWrapper}>
              <Link reloadDocument to={ROUTES.PRODUCTS.CAMERA} id="heroCamera">
                <div className={styles.product}>
                  {/* <div className={styles.productIcon}> */}
                  <ZunuCameraIcon className={styles.productIcon} />
                  {/* </div> */}
                  <p className={styles.productName}>Camera</p>
                </div>
              </Link>

              {/* {device !== DEVICE.MOBILE && */}
              <hr />
              {/* } */}

              <Link reloadDocument to={ROUTES.PRODUCTS.MAIL} id="heroMail">
                <div className={styles.product}>
                  {/* <div className={styles.productIcon}> */}
                  <ZunuMailIcon className={styles.productIcon} />
                  {/* </div> */}
                  <p className={styles.productName}>Mail</p>
                </div>
              </Link>

              {/* {device !== DEVICE.MOBILE && */}
              <hr />
              {/* } */}

              <Link reloadDocument to={ROUTES.PRODUCTS.MESSAGE} id="heroMessage">
                <div className={styles.product}>
                  {/* <div className={styles.productIcon}> */}
                  <ZunuMessageIcon className={styles.productIcon} />
                  {/* </div> */}
                  <p className={styles.productName}>Message</p>
                </div>
              </Link>

              {/* {device !== DEVICE.MOBILE && */}
              <hr />
              {/* } */}

              <Link reloadDocument to={ROUTES.PRODUCTS.DRIVE} id="heroDrive">
                <div className={styles.product}>
                  {/* <div className={styles.productIcon}> */}
                  <ZunuDriveIcon className={styles.productIcon} />
                  {/* </div> */}
                  <p className={styles.productName}>Drive</p>
                </div>
              </Link>
            </div>
          </div>
          {device !== DEVICE.MOBILE &&
            <p className={styles.description}>
              Experience 100% privacy for your photos, videos, messages, emails, and files on Zunu. For Android, iOS, Windows, Linux platforms and even USB storage devices.
            </p>
          }
          {/* <HomeDownloadCaptionEnum></HomeDownloadCaptionEnum> */}

          {/* <div
            id="download"
            className={`${styles.downlodButtonContainer}`}
            // ref={ref}
            onClick={handleDownloadClick}
          >
            {os !== PLATFORM.IOS && os !== PLATFORM.AND && (
              <Button text="Download" />
            )}
          </div> */}
        </div>
      </div>
      <div className={styles.homePageSectionContainer}>
        <div className={styles.offerContainer}>
          {device === DEVICE.DESKTOP &&
            <ScrollArrow className={styles.scrollArrow} title='scroll down' onClick={scrollPage} id="heroScroll" />
          }
          <p className={styles.offerText}>Get World's Best Privacy free for 2 years.</p>
        </div>

        <FloaterDownload />


        <ZunuDescription />
        {/* <HomePricingCardsEnum></HomePricingCardsEnum> */}
        <HomeDeviceToDeviceEncryption />
        <ZunuThings />
        {/* <DecentralizedPrivacy /> */}
        {/* <SwissPrivacy /> */}
        {/* <ChooseServer /> */}
        <Captions />
        <div className={styles.videoConatiner}>
          <ProductVideos videos={VIDEOS.ZUNU} title="Introducing ZUNU" />
        </div>
        <ProductCards />
        {/* <WhitePapers whitepapers={WHITEPAPERS.ZUNU} /> */}
        {/* <Testimonials data={TESTIMONIALS.ALL} heading="What our Users say about ZUNU" /> */}
        <ProductFaqs data={QUESTIONS.zunu.faq} url="/support" heading="Frequently Asked Questions" background="#E8ECFD" className={styles.faqBackground}  backgroundImg="none" />
      </div>
    </section>
  );
};

export default Home;
